import React from "react"

import Header       from "../components/Header/upcc"
import WallPodcasts from "../components/upcc/wall-podcasts"
import Podcasts     from "../components/upcc/podcasts"
import Footer       from "../components/share/footer"


export default () => {

  return (
    <>
      <Header/>

      <div class="upcc-container">

        <WallPodcasts/>

        {/*<Podcasts/>*/}

      </div>

      <Footer/>
    </>
  )
}
